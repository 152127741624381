<template>
  <section class="invoice-add-wrapper">

    <validation-observer ref="simpleRules">
      <b-form
        v-if="show"
        @submit.prevent="onSubmit"
      >
        <b-row class="invoice-add">

          <!-- Col: Left (Invoice Container) -->
          <b-col
            cols="12"
            xl="9"
            md="8"
          >
            <b-card
              class="purchase-add-wrapper"
              no-body
            >
              <b-card-body>
                <b-row>
                  <!-- Col: Proveedor -->
                  <b-col
                    cols="12"
                    xl="4"
                    md="6"
                  >
                    <h6 class="mb-1 mt-1">
                      Proveedor:
                    </h6>
                    <validation-provider
                      #default="{ errors }"
                      name="proveedor"
                      rules="required"
                    >
                      <b-form-group>
                        <v-select
                          v-if="suppliersOptions"
                          input-id="select-suppliers"
                          :options="suppliersOptions"
                          :label="supplierSelectLabel"
                          placeholder="Selecciona el proveedor"
                          @input="val => setSupplierData(val)"
                          @search="onSearchSuppliers"
                        >
                          <template #list-header>
                            <li
                              class="add-new-supplier-header d-flex align-items-center my-50"
                              @click="supplierAddSidebarActive = true"
                            >
                              <feather-icon
                                icon="PlusIcon"
                                size="16"
                              />
                              <span class="align-middle ml-25">Nuevo proveedor</span>
                            </li>
                          </template>
                          <template v-slot:option="option">
                            {{ option.nit }} - {{ option.label }}
                          </template>
                          <template slot="no-options">
                            Lo siento, no se encontraron proveedores
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>

                    <!-- Info del proveedor -->
                    <p v-if="supplierData">
                      <!-- Nombre -->
                      <span class="font-weight-bold">Nombre:</span>
                      {{ supplierData.name }}
                      <br>
                      <!-- NIT -->
                      <span class="font-weight-bold">NIT:</span>
                      {{ supplierData.nit }}
                      <br>
                      <!-- Dirección -->
                      <span class="font-weight-bold">Dirección:</span>
                      {{ (supplierData.address) ? supplierData.address : '-' }}
                    </p>
                  </b-col>

                  <!-- Col: Fecha -->
                  <b-col
                    cols="12"
                    xl="4"
                    md="6"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="fecha"
                      rules="required"
                    >
                      <b-form-group>
                        <h6 class="mb-1 mt-1">
                          Fecha:
                        </h6>
                        <b-form-datepicker
                          v-model="purchaseData.purchase_date"
                          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                          locale="es"
                          placeholder="Fecha de la compra"
                          selected-variant="warning"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- Col: # Compra -->
                  <b-col
                    cols="12"
                    xl="4"
                    md="6"
                  >
                    <b-form-group>
                      <h6 class="mb-1 mt-1">
                        Doc. Externo:
                      </h6>
                      <b-input-group>
                        <b-form-input
                          id="purchase-data-id"
                          v-model="purchaseData.document"
                          placeholder="Documento externo (opcional)"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-body>

              <b-card-body class="invoice-padding form-item-section">
                <div
                  ref="form"
                  class="repeater-form"
                  :style="{height: trHeight}"
                >
                  <b-row
                    v-for="(product, index) in purchaseData.products"
                    :key="index"
                    ref="row"
                    class="pb-2"
                  >
                    <!-- Item Form -->
                    <!-- ? This will be in loop => So consider below markup for single item -->
                    <b-col cols="12">
                      <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                      <div class="d-none d-lg-flex">
                        <b-row class="flex-grow-1 px-1">
                          <!-- Single Item Form Headers -->
                          <b-col
                            cols="12"
                            lg="5"
                          >
                            Producto
                          </b-col>
                          <b-col
                            cols="12"
                            lg="3"
                          >
                            Costo
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                          >
                            Cantidad
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                          >
                            Precio
                          </b-col>
                        </b-row>
                        <div class="form-item-action-col" />
                      </div>

                      <!-- Form Input Fields OR content inside bordered area  -->
                      <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                      <div class="d-flex border rounded">
                        <b-row class="flex-grow-1 p-2">
                          <!-- Single Item Form Headers -->
                          <b-col
                            cols="12"
                            lg="5"
                          >
                            <label class="d-inline d-lg-none">Producto</label>
                            <v-select
                              :options="productsOptions"
                              :reduce="product => product"
                              input-id="select-products"
                              :clearable="false"
                              class="mb-2 item-selector-title"
                              placeholder="Seleccione producto"
                              @search="onSearchProducts"
                              @input="val => updateItem(index, val)"
                            >
                              <template #list-header>
                                <li
                                  class="add-new-product-header d-flex align-items-center my-50"
                                  @click="productAddSidebarActive = true"
                                >
                                  <feather-icon
                                    icon="PlusIcon"
                                    size="16"
                                  />
                                  <span class="align-middle ml-25">Nuevo producto</span>
                                </li>
                              </template>
                              <template slot="no-options">
                                Lo siento, no se encontraron productos
                              </template>
                            </v-select>
                          </b-col>
                          <b-col
                            cols="12"
                            lg="3"
                          >
                            <label class="d-inline d-lg-none">Cost</label>
                            <b-form-input
                              v-model="product.cost"
                              type="number"
                              min="0.01"
                              step="any"
                              class="mb-2"
                            />
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                          >
                            <label class="d-inline d-lg-none">Qty</label>
                            <b-form-input
                              v-model="product.quantity"
                              type="number"
                              min="1"
                              step="any"
                              class="mb-2"
                            />
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                          >
                            <label class="d-inline d-lg-none">Price</label>
                            <p class="mb-1">
                              Q.{{ product.cost * product.quantity }}
                            </p>
                          </b-col>

                        </b-row>
                        <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                          <feather-icon
                            size="16"
                            icon="XIcon"
                            class="cursor-pointer"
                            @click="removeItem(index)"
                          />
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div>
                <b-button
                  v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                  variant="outline-warning"
                  size="sm"
                  @click="addItem"
                >
                  Agregar producto
                </b-button>
              </b-card-body>

              <!-- Invoice Description: Total -->
              <b-card-body class="invoice-padding pb-0">
                <b-row>
                  <!-- Col: Contact Person -->
                  <b-col
                    cols="12"
                    xl="4"
                    md="6"
                  >
                    <h6 class="mb-1 mt-1">
                      Contacto:
                    </h6>
                    <validation-provider
                      #default="{ errors }"
                      name="contacto"
                      rules="required"
                    >
                      <b-form-group>
                        <b-form-input
                          id="invoice-data-sales-person"
                          v-model="purchaseData.contact"
                          placeholder="Nombre del vendedor"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- Col: Total -->
                  <b-col
                    cols="12"
                    md="8"
                    class="mt-md-6 d-flex justify-content-end"
                    order="1"
                    order-md="2"
                  >
                    <div class="purchase-total-wrapper">
                      <div class="purchase-total-item">
                        <p class="purchase-total-title">
                          Total:
                        </p>
                        <p class="purchase-total-amount">
                          Q.{{ purchaseTotal }}
                        </p>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-card-body>

              <!-- Spacer -->
              <hr class="invoice-spacing">

              <!-- Note -->
              <b-card-body class="invoice-padding pt-0">
                <span class="font-weight-bold">Observaciones: </span>
                <b-form-textarea
                  v-model="purchaseData.comments"
                  placeholder="Comentarios y anotaciones relacionadas con la compra"
                />
              </b-card-body>

              <!-- Supplier form -->
              <suppliers-add
                :supplier-add-sidebar-active.sync="supplierAddSidebarActive"
                @refreshData="getSuppliers"
              />

              <!-- Product form -->
              <products-add
                :product-add-sidebar-active.sync="productAddSidebarActive"
                @refreshData="getProducts"
              />

            </b-card>
          </b-col>

          <!-- Right Col: Card -->
          <b-col
            cols="12"
            md="4"
            xl="3"
            class="invoice-actions mt-md-0 mt-2"
          >
            <!-- Action Buttons -->
            <b-card>
              <b-form-group label="Tipo de pago">
                <b-form-radio-group
                  v-model="purchaseData.payment_type"
                  :options="payOptions"
                  name="pay-types"
                  stacked
                />
              </b-form-group>

              <!-- Button: Print -->
              <b-button
                v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                variant="outline-warning"
                block
                type="submit"
              >
                Comprar
              </b-button>
            </b-card>
          </b-col>

        </b-row>

      </b-form>
    </validation-observer>

  </section>
</template>

<script>
import axios from '@axios'
import { heightTransition } from '@core/mixins/ui/transition'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import SuppliersAdd from '../../contacts/suppliers/SuppliersAdd.vue'
import ProductsAdd from '../../catalogs/products/ProductsAdd.vue'

export default {
  components: {
    vSelect,
    SuppliersAdd,
    ProductsAdd,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],

  data() {
    return {
      // Purchase
      purchaseData: {
        supplier_id: '',
        document: '',
        contact: '',
        purchase_date: this.getCurrentDate(),
        payment_type: '',
        comments: '',
        products: [JSON.parse(JSON.stringify(
          { // Detail template
            product_id: null,
            cost: 0,
            quantity: 0,
          },
        ))],
      },

      // Detail template
      formProductBlank: {
        product_id: null,
        cost: 0,
        quantity: 0,
      },

      payOptions: [
        { text: 'Efectivo', value: 'efectivo' },
        { text: 'Tarjeta', value: 'tarjeta' },
        { text: 'Crédito', value: 'credito' },
      ],

      // Suppliers
      suppliersOptions: [],
      supplierAddSidebarActive: false,
      productAddSidebarActive: false,

      // Products
      productsOptions: [],
      show: true,

      supplierSelectLabel: 'label',
      supplierData: {},
    }
  },

  computed: {
    purchaseTotal() {
      let total = 0
      this.purchaseData.products.forEach(element => {
        total += element.cost * element.quantity
      })
      return Number(total).toFixed(2)
    },
  },

  mounted() {
    this.initTrHeight()
  },

  created() {
    this.getSuppliers()
    this.getProducts()
    window.addEventListener('resize', this.initTrHeight)
  },

  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },

  methods: {
    getSuppliers() {
      this.suppliersOptions = []
      axios
        .get('supplier?perPage=10&sortField=id&sortDesc=desc&filterField=status&filterValue=Y')
        .then(response => {
          response.data.data.forEach(element => {
            this.suppliersOptions.push({
              label: element.name,
              id: element.id,
              nit: element.nit,
            })
          })
        })
        .catch(error => {
          this.showErrors(error)
        })
    },

    // funcion que valida con una expresion regular si la cadena es un numero o no
    isNIT(value) {
      if (/^\d+$/.test(value) || value === 'CF') {
        return true
      }
      return false
    },

    onSearchSuppliers(search, loading) {
      // validar si search es un numero
      if (this.isNIT(search)) {
        this.supplierSelectLabel = 'nit'
      } else {
        this.supplierSelectLabel = 'label'
      }

      if (search.length) {
        loading(true)
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          axios
            .get(`supplier?filterField=status&filterValue=Y&query=${search}`)
            .then(response => {
              loading(false)
              this.suppliersOptions = []
              response.data.data.forEach(element => {
                this.suppliersOptions.push({
                  label: element.name,
                  id: element.id,
                  nit: element.nit,
                })
              })
            })
            .catch(error => {
              this.showErrors(error)
            })
        }, 300)
      } else {
        this.getSuppliers()
      }
    },

    setSupplierData(supplier) {
      if (supplier) {
        // get Supplier
        axios
          .get(`supplier/${supplier.id}`)
          .then(response => {
            this.purchaseData.contact = response.data.data.contact_name
            this.purchaseData.supplier_id = response.data.data.id
            this.supplierData = response.data.data
          })
          .catch(error => {
            this.showErrors(error)
          })
      }
    },

    getProducts() {
      this.productsOptions = []

      const filterArray = [
        { field: 'status', value: 'Y' },
      ]

      axios
        .get(`product?perPage=10&sortField=id&sortDesc=desc&filters=${JSON.stringify(filterArray)}`)
        .then(response => {
          response.data.data.forEach(element => {
            this.productsOptions.push({
              label: element.name,
              id: element.id,
              cost: element.cost,
              description: element.description,
            })
          })
        })
        .catch(error => {
          this.showErrors(error)
        })
    },

    onSearchProducts(search, loading) {
      this.productsOptions = []

      const filterArray = [
        { field: 'status', value: 'Y' },
      ]

      if (search.length) {
        loading(true)
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          axios
            .get(`product?filters=${JSON.stringify(filterArray)}&query=${search}`)
            .then(response => {
              loading(false)
              this.productsOptions = []
              response.data.data.forEach(element => {
                this.productsOptions.push({
                  label: element.name,
                  id: element.id,
                  cost: element.cost,
                  description: element.description,
                })
              })
            })
            .catch(error => {
              this.showErrors(error)
            })
        }, 300)
      } else {
        this.getProducts()
      }
    },

    onSubmit(event) {
      event.preventDefault()
      this.saveData()
    },

    saveData() {
      axios
        .post('purchase', this.purchaseData)
        .then(async response => {
          if (response.data.res) {
            this.clearForm()
            this.makeToast('success', 'Nuevo ingreso', `Se ha realizado el ingreso correctamente, transacción: #${response.data.data.id}.`)
            await new Promise(resolve => { window.setTimeout(resolve, 1000) })
            this.$emit('addPurchaseTab', response.data.data.id)
          } else {
            throw (response)
          }
        })
        .catch(error => {
          this.showErrors(error)
        })
    },

    clearForm() {
      // Reset our form values
      this.purchaseData.supplier_id = ''
      this.purchaseData.purchase_date = ''
      this.purchaseData.document = ''
      this.purchaseData.contact = ''
      this.purchaseData.comments = ''
      this.purchaseData.products = []

      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },

    // Métodos para manejar productos dinamicamente
    // =======================================================
    addItem() {
      this.$refs.form.style.overflow = 'hidden'
      this.purchaseData.products.push(JSON.parse(JSON.stringify(this.formProductBlank)))

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
        setTimeout(() => {
          this.$refs.form.style.overflow = null
        }, 350)
      })
    },

    removeItem(index) {
      this.purchaseData.products.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },

    updateItem(index, val) {
      const foundItem = this.purchaseData.products.find(element => element.product_id === val.id)
      if (foundItem) {
        this.makeToast('danger', 'Producto ya seleccionado', `El producto ${val.label} ya fue seleccionado anteriormente, edite la cantidad si es necesario.`)
        this.removeItem(index)
      } else {
        const { id, cost, description } = val
        this.purchaseData.products[index].product_id = id
        this.purchaseData.products[index].cost = cost
        this.purchaseData.products[index].quantity = 1
        this.purchaseData.products[index].description = description
      }
    },

    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    // =======================================================
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.purchase-add-wrapper {
  .add-new-supplier-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}

.purchase-total-wrapper {
  width: 100%;
  max-width: 10rem;
  .purchase-total-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .purchase-total-title {
      margin-bottom: 0.35rem;
    }
    .purchase-total-amount {
      margin-bottom: 0.35rem;
      font-weight: 600;
    }
  }
}

.add-new-product-header {
  padding: $options-padding-y $options-padding-x;
  color: $success;

  &:hover {
    background-color: rgba($success, 0.12);
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>
